import React from "react";
import classnames from "classnames";
import education from "./education";
import expertise from "./expertise";
import language from "./language";
import masters from "./masters";
import styles from "./personPage.module.css";

function PersonPage(props) {
  let educationArray = education[props.id]
    ? education[props.id].split("; ")
    : [];
  let expertiseArray = expertise[props.id]
    ? expertise[props.id].split("; ")
    : [];
  let languageArray = language[props.id] ? language[props.id].split("; ") : [];
  let mastersArray = masters[props.id] ? masters[props.id].split("; ") : [];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <div className={classnames(styles.column, styles.left)}>
        <img className={styles.image} src={props.imgSrc} alt="bilde av en ansatt" />
        <h2>
          {props.name}
        </h2>
        <h5 className={styles.title}>{props.title}</h5>
        <p>
          <a className="redText" href={`mailto:${props.email}`}>
            {props.email}
          </a>
        </p>
        <p>tfl. <a href={`tel:${props.tlf}`}>&nbsp;{props.tlf}</a></p>
        {props.mobTlf && <p>mob. {props.mobTlf}</p>}
      </div>
      <div className={classnames(styles.column, styles.right)}>
        {educationArray.length > 0 && (
          <>
            <h4>Utdannelse og praksis:</h4>
            <ul className={styles.list}>
              {educationArray.map((item, i) => (
                <li key={i} className={styles.listItem}>
                  {item}
                </li>
              ))}
            </ul>
          </>
        )}
        {expertiseArray.length > 0 && (
          <>
            <h4>Særlige fagområder:</h4>
            <ul className={styles.list}>
              {expertiseArray.map((item, i) => (
                <li key={i} className={styles.listItem}>
                  {item}
                </li>
              ))}
            </ul>
          </>
        )}
        {languageArray.length > 0 && (
          <>
            <h4>Språk:</h4>
            <ul className={styles.list}>
              {languageArray.map((item, i) => (
                <li key={i} className={styles.listItem}>
                  {item}
                </li>
              ))}
            </ul>
          </>
        )}
        {mastersArray.length > 0 && (
          <>
            <h4>Masteroppgave:</h4>
            <ul className={styles.list}>
              {mastersArray.map((item, i) => (
                <li key={i} className={styles.listItem}>
                  {item}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}

export default PersonPage;
