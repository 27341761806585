import React from "react";

function PageNotFound() {
  return (
    <div>
      <h2>404</h2>
      <h2>Siden du leter etter finnes ikke</h2>
    </div>
  );
}

export default PageNotFound;
