import classnames from "classnames";
import React from "react";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import Button from "../button/button";
import ArrowDown from "../icons/arrowDown";
import styles from "./header.module.css";

const scrollDown = () => {
  window.scrollTo(0, window.innerHeight);
};

const renderHeader = (path) => {
  let text = "Velkommen til oss!";
  let img = "/f436dd41-c43d-4099-b2da-55cd9491ab90.jpeg";
  let className = classnames(styles.image, styles.imageSmall);

  switch (path) {
    case "/om-oss":
      text = "Om Oss";
      img = "/background.jpg";
      className = classnames(styles.image, styles.imageSmall);
      break;
    case "/priser":
      text = "Priser";
      img = "/background.jpg";
      className = classnames(styles.image, styles.imageSmall);
      break;
    case "/kontakt":
      text = "Kontakt";
      img = "/about.jpg";
      className = classnames(styles.image, styles.imageSmall);
      break;
    default:
      break;
  }

  return (
    <div className={className} style={{ backgroundImage: `url(${img})` }}>
      <h1 className={styles.largeText}>{text}</h1>
      <div className={styles.overlay}></div>
      <Switch>
        <Route path="/" exact>
          <div className={styles.scrollButton}>
            <Button onClick={scrollDown} className={styles.smallButton}>
              <ArrowDown />
            </Button>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

function Header() {
  const [menuToggled, setMenuToggled] = React.useState(false);
  return (
    <header className={styles.container}>
      <div className={styles.titleAndNavContainer}>
        <div className={styles.titleAndNav}>
          <div className={styles.titleAndLogo}>
            <Link to="/">
              <div className={styles.logo}>
                <h2>Ski Barneklinikk</h2>
              </div>
            </Link>
          </div>
          <div
            className={classnames(styles.navigationIconMobile, {
              [styles.navigationIconToggled]: menuToggled,
            })}
            onClick={() => {
              setMenuToggled(!menuToggled);
            }}
          >
            <div className={styles.bar1}></div>
            <div className={styles.bar2}></div>
            <div className={styles.bar3}></div>
          </div>
          <div
            className={classnames(styles.navigationOverlayMobile, {
              [styles.navigationMenuToggled]: menuToggled,
            })}
            onClick={() => {
              setMenuToggled(!menuToggled);
            }}
          >
            <div className={styles.navigationMenuMobile}>
              <NavLink
                className={styles.navigation_item_mobile}
                activeClassName={styles.navigation_item__active}
                to="/"
                exact
              >
                Hjem
              </NavLink>
              <NavLink
                className={styles.navigation_item_mobile}
                activeClassName={styles.navigation_item__active}
                to="/om-oss"
                exact
              >
                Om oss
              </NavLink>
              <NavLink
                className={styles.navigation_item_mobile}
                activeClassName={styles.navigation_item__active}
                to="/priser"
                exact
              >
                Priser
              </NavLink>
              <NavLink
                className={styles.navigation_item_mobile}
                activeClassName={styles.navigation_item__active}
                to="/kontakt"
                exact
              >
                Kontakt
              </NavLink>
            </div>
          </div>
          <nav className={styles.navigation}>
            <NavLink
              className={styles.navigation_item}
              activeClassName={styles.navigation_item__active}
              to="/"
              exact
            >
              Hjem
            </NavLink>
            <NavLink
              className={styles.navigation_item}
              activeClassName={styles.navigation_item__active}
              to="/om-oss"
              exact
            >
              Om oss
            </NavLink>
            <NavLink
              className={styles.navigation_item}
              activeClassName={styles.navigation_item__active}
              to="/priser"
              exact
            >
              Priser
            </NavLink>
            <NavLink
              className={styles.navigation_item}
              activeClassName={styles.navigation_item__active}
              to="/kontakt"
              exact
            >
              Kontakt
            </NavLink>
          </nav>
        </div>
      </div>
      <Switch>
        <Route path="/om-oss" exact>
          {renderHeader("/om-oss")}
        </Route>
        <Route path="/kontakt" exact>
          {renderHeader("/kontakt")}
        </Route>
        <Route path="/priser" exact>
          {renderHeader("/priser")}
        </Route>
        <Route path="/" exact>
          {renderHeader("/")}
        </Route>
      </Switch>
    </header>
  );
}

export default Header;
