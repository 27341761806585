const expertise = {
    torbjorn: 'Arbeidsrett; Barne- og familierett; Arv og skifte; Husleierett; Kjøpsrett; Eiendomsoverdragelse; Fast eiendoms rettshold; NAV-saker;  Autorisert familiemekler;',
    oistein: 'Konkurs; Arv og skifte; Familierett',
    martin: 'Straffesaker; Konkurs; Arv og familierett; Skifte; Barnefordeling og barnevern',
    knut: 'Strafferett (forsvarer eller bistandsadvokat); Bostyrer i konkurs; Arv og skifte; Familierett; Barnevern; Barnefordeling; Arbeidsrett; Kontrakts- og erstatningsrett m.m.',
    thomas: 'Arbeidsrett; Selskapsrett; Kontraktsrett; Merverdiavgift/særavgifter; Familie, arv og skifte; Nav- og trygdesaker;  Generell tvisteløsning; Prosedyre',
    agnieszka: 'Familie, arv og skifte; Arbeidsrett; Barnevern; Bolig og eiendom; Utlendingsrett; Nav- og trygdesaker; Psykisk helsevern; Prosedyre',
    line: '',
};

export default expertise;