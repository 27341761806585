import React from "react";
import { Link } from "react-router-dom";
import styles from "./aboutCard.module.css";

function AboutCard(props) {
  return (
    <div className={styles.container}>
      <h4>{props.name}</h4>

      <p>{props.title}</p>
      <Link to={`/om-oss${props.href}`}>
        <img
          className={styles.image}
          src={props.imgSrc}
          alt="bilde av en ansatt"
        />
      </Link>

      <div className={styles.text}>
        <p>
          <a className="redText" href={`mailto:${props.email}`}>
            {props.email}
          </a>
        </p>
        {props.tlf && <p>tfl. {props.tlf}</p>}
        {props.mobTlf && <p>mob. {props.mobTlf}</p>}
      </div>
    </div>
  );
}

export default AboutCard;
