import React from 'react';
import { Link } from "react-router-dom";
import styles from './footer.module.css';

function Footer () {
    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <p>Ski Barneklinikk</p>
                <p>Org.nr.:  814 039 072</p>
            </div>
            <div className={styles.column}>
                <p>Industriveien 3a 1424 Ski</p>
                <p>Telefon: <a href="tel:24 20 05 11">&nbsp;24 20 05 11</a></p>
                <p>E-post:  <a href="mailto:post@skibarneklinikk.no">post@skibarneklinikk.no</a></p> 
            </div>
            <div className={styles.column}>
                <Link to="/terms"><p>Personvern</p></Link>
            </div>
        </div>
    )
}

export default Footer;
