const language = {
    torbjorn: '',
    oistein: '',
    martin: '',
    knut: '',
    thomas: '',
    agnieszka: 'Norsk, polsk og engelsk',
    line: '',
};

export default language;