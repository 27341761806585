import React from "react";
import styles from "./pricesPage.module.css";

function PricesPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.container}>
      <h4>
        Barnelege Anna Owczarz har avtale med Helse Sør Øst , våre små pasienter
        får dermed gratis behandling med refusjon fra Helfo, etter henvisning
        fra lege.
      </h4>

      <h4>
        Egenandel 403 kr. Det er samme egenandel for barn over 16 år for
        forhåndsavtalte telefon-eller videokonsultasjoner.
      </h4>

      <h4>
        Egenandelen og event pris for forbruksmaterialet skal betales i
        resepsjonen rett etter timen. Vi gir ikke ut faktura.
      </h4>

      <p>
        Hvis du ønsker å endre eller avbestille timen din, vennligst gjør dette
        minst 24 timer før timen ved å ringe oss på 24 20 05 11 på virkedager.
        Vår telefon er åpen 08-15, man-fredag. Avbestiller du for sent eller
        ikke møter opp vil du bli belastet ett ikke-møtt-gebyr på 403 kr. Dette
        gjelder også e konsultasjoner.
      </p>

      <p>
        Hvis man er betydelig forsinket, må vi avtale ny time og forsinkelsen
        vil gjelde som ikke møtt.
      </p>

      <p>
        Dersom man ikke kan møte grunnet akutt sykdom bør man gi beskjed så
        snart som mulig. Vi vil som oftest tilby telefon-eller videokonsultasjon
        hvis indisert.
      </p>
    </div>
  );
}

export default PricesPage;
