import React from "react";
import { Switch, Route } from "react-router-dom";

import "./app.css";
import "./typography.css";
import AboutPage from "./aboutPage/aboutPage";
import HomePage from "./homePage/homePage";
import ContactPage from "./contactPage/contactPage";
import PersonPage from "./personPage/personPage";
import PricesPage from "./pricesPage/pricesPage";
import PageNotFound from "./notFoundPage/notFoundPage";
import Header from "./header/header";
import Footer from "./footer/footer";

function App() {
  return (
    <div className="App">
      <Header />

      <Switch>
        <Route path="/om-oss" exact>
          <AboutPage />
        </Route>

        <Route path="/om-oss/anna-owczarz" exact>
          <PersonPage
            id="anna"
            title="Spesialist i barnesykdommer"
            name="Anna Owczarz"
            email="post@skibarneklinikk.no"
            tlf="24 20 05 11"
            imgSrc="/anna-2.jpg"
          />
        </Route>
        <Route path="/om-oss/monika" exact>
          <PersonPage
            id="monika"
            title="Legesekretær"
            name="Monika"
            tlf="24 20 05 11"
            email="post@skibarneklinikk.no"
            imgSrc="/monika-2.jpg"
          />
        </Route>
        <Route path="/om-oss/dominika" exact>
          <PersonPage
            id="dominika"
            title="Legesekretær"
            name="Dominika"
            tlf="24 20 05 11"
            email="post@skibarneklinikk.no"
            imgSrc="/dominika-2.jpg"
          />
        </Route>

        <Route path="/kontakt" exact>
          <ContactPage />
        </Route>
        <Route path="/priser" exact>
          <PricesPage />
        </Route>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>

      <Footer />
    </div>
  );
}

export default App;
