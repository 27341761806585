import React from "react";
import LinkButton from "../button/linkButton";

import styles from "./homePage.module.css";

function Home() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <h1>Ski Barneklinikk</h1>

      <p>
        Ski Barneklinikk drives av doktor Anna, barnelege med lang erfaring
        innen barnesykdommer. Hos oss møter du også to legesekretærer som vil
        legge til rette for et hyggelig besøk. Vårt personal er flytende i
        norsk, polsk, engelsk og russisk språk. Dersom du har spørsmål før eller
        etter timen din er du vekommen til å ta kontakt med oss.
      </p>

      <p>
        Vi tar imot barn med alle helseproblemer inkludert astma og allergier.
        Vi tilbyr også allergivaksinering.
      </p>

      <p>
        Fordi vi har avtale med Helse Sør Øst, får våre små pasienter gratis
        behandling med refusjon fra Helfo, etter henvisning fra lege. Barn som
        er over 16 år gamle betaler egenandel på kr. 403. Ved lungetesting
        betaler pasienter egenandel på 113 kr for forbruksmaterialet uavhengig
        av alder.
      </p>

      <p>
        Vi tar ingen blodprøver selv, men henviser pasienter til blodprøver
        eller andre undersøkelser der hvor det er indisert.
      </p>

      <div className={styles.buttons}>
        <LinkButton href="/om-oss">
          <h3>Om oss</h3>
        </LinkButton>
        <LinkButton href="/kontakt">
          <h3>Kontakt</h3>
        </LinkButton>
        <LinkButton href="/priser">
          <h3>Priser</h3>
        </LinkButton>
      </div>
    </div>
  );
}

export default Home;
