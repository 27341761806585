import React from "react";
import classnames from "classnames";

import styles from "./contactPage.module.css";

function Contact() {
  return (
    <div className={styles.verticalContainer}>
      <div className={styles.container}>
        <div className={styles.column}>
          <h3>
            Du finner oss i Industriveien 3 A i Ski i nye flotte lokaler med
            universell utforming. Vi har gratis parkering rett foran bygget.{" "}
          </h3>
          <h4>
            Pasienttelefon <a href="tel:24 20 05 11">&nbsp;24 20 05 11</a>{" "}
            <br /> er åpen mandager - fredager kl. 08:00 - kl. 15:00
          </h4>
          <ul className={styles.list}>
            <li>
              epost:
              <a className="redText" href={`mailto:post@skibarneklinikk.no`}>
                &nbsp;post@skibarneklinikk.no
              </a>
              &nbsp;(ikke skriv sensitiv informasjon)
            </li>

            <li>adresse: Industriveien 3a, 1424, Ski</li>
          </ul>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d503.0536984275293!2d10.851354129303182!3d59.71261937523146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46415cfe9d12a1fd%3A0x32d9b6f700ad2de7!2sIndustriveien%203%20A%2C%201406%20Ski!5e0!3m2!1sen!2sno!4v1605372001391!5m2!1sen!2sno"
            frameborder="0"
            allowfullscreen
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
        <div className={classnames(styles.column, styles.right)}>
          <iframe
            src="https://helserespons.no/internettbestilling/skibk/?other=true"
            className={styles.formIframe}
            frameborder="0"
            allowFullScreen
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
