import React from "react";
import AboutCard from "../aboutCard/aboutCard";
import { Gallery } from "../ImageGallery/ImageGallery";

import styles from "./aboutPage.module.css";

function About() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <AboutCard
        name="Dr. Anna Owczarz"
        imgSrc="/anna-2.jpg"
        title="Spesialist i barnesykdommer"
        href="/anna-owczarz"
      />
      <AboutCard
        name="Monika"
        imgSrc="/monika-2.jpg"
        title="Legesekretær"
        href="/monika"
      />
      <AboutCard
        name="Dominika"
        imgSrc="/dominika-2.jpg"
        title="Legesekretær"
        href="/dominika"
      />

      <Gallery />
    </div>
  );
}

export default About;
