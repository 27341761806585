import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import styles from "./imageGallery.module.css";

const images = [
  {
    original: "/01eda4e9-a96f-4c20-85e1-7e00a6937fab.jpeg",
    thumbnail: "/01eda4e9-a96f-4c20-85e1-7e00a6937fab.jpeg",
  },
  {
    original: "/5bb3d8b0-5ddf-4ce8-aa0e-7cc0227fdaae.jpeg",
    thumbnail: "/5bb3d8b0-5ddf-4ce8-aa0e-7cc0227fdaae.jpeg",
  },
  {
    original: "/9720b339-4c61-4a43-9b1d-d0f9dba4f641.jpeg",
    thumbnail: "/9720b339-4c61-4a43-9b1d-d0f9dba4f641.jpeg",
  },
  {
    original: "/47851543-1e0d-4b1c-ace1-b947739452f2.jpeg",
    thumbnail: "/47851543-1e0d-4b1c-ace1-b947739452f2.jpeg",
  },
  {
    original: "/d3ebd43f-b1ee-41e9-b80a-984ca949a4f8.jpeg",
    thumbnail: "/d3ebd43f-b1ee-41e9-b80a-984ca949a4f8.jpeg",
  },
  {
    original: "/e396662e-b64f-43b8-bf92-b282a1d7efbb.jpeg",
    thumbnail: "/e396662e-b64f-43b8-bf92-b282a1d7efbb.jpeg",
  },
  {
    original: "/ea33b61e-b632-4b26-b92c-7b9f7cdbb490.jpeg",
    thumbnail: "/ea33b61e-b632-4b26-b92c-7b9f7cdbb490.jpeg",
  },
  {
    original: "/f436dd41-c43d-4099-b2da-55cd9491ab90.jpeg",
    thumbnail: "/f436dd41-c43d-4099-b2da-55cd9491ab90.jpeg",
  },
  {
    original: "/6dc60580-522d-467b-b60d-84866a5f28d0.jpeg",
    thumbnail: "/6dc60580-522d-467b-b60d-84866a5f28d0.jpeg",
  },
];

export function Gallery() {
  return <ImageGallery additionalClass={styles.container} items={images} />;
}
